
























import { defineComponent, PropType } from "@vue/composition-api";
import { UsageRadioButtonOption } from "@/features/usage-calculator";

export default defineComponent({
  name: "RadioButtons",
  props: {
    options: {
      type: Array as PropType<UsageRadioButtonOption[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    currentValue: {
      type: [String, Number],
      default: undefined,
    },
    optionsClass: {
      type: String,
      default: "",
    },
  },
});
