<template>
  <div class="flex flex-col p-6 bg-dark-blue-500">
    <div class="flex">
      <img :src="result.icon" :alt="result.title" />
      <p class="ml-2 text-xl font-bold uppercase" :class="result.titleColor">
        {{ result.title }}
      </p>
    </div>

    <p class="py-2 text-white">
      {{ result.text }}
    </p>
    <div class="relative">
      <svg
        width="100%"
        height="5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="100%" height="5" fill="url(#paint0_linear_1821_5211)" />
        <defs>
          <linearGradient
            id="paint0_linear_1821_5211"
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5BFF89" />
            <stop offset="0.505208" stop-color="#FBFF43" />
            <stop offset="1" stop-color="#FF4141" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        class="absolute transition-all duration-500 ease-in-out transform -translate-x-1/2 top-1"
        :style="`left: ${scalePercentage}%;`"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.0659 14.9951L10 13.2162L4.85888 15.0558L0 16.7734L10 1.92127L20 16.7734L15.0659 14.9951Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";
import HighSvg from "@/assets/svgs/UsageCalculator/hoch.svg";
import IncreasedSvg from "@/assets/svgs/UsageCalculator/erhoeht.svg";
import AverageSvg from "@/assets/svgs/UsageCalculator/durchschnitt.svg";
import LowSvg from "@/assets/svgs/UsageCalculator/gering.svg";

export default defineComponent({
  name: "UsageResultSlider",
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    // Get Value options from settings object
    const livingValues = props?.settings.find(
      (setting) => setting.key === "living"
    )?.values;
    const waterValues = props?.settings.find(
      (setting) => setting.key === "water"
    )?.values;
    const personsLivingValues = props?.settings.find(
      (setting) => setting.key === "personsLiving"
    )?.values;
    const personsOfficeValues = props?.settings.find(
      (setting) => setting.key === "personsOffice"
    )?.values;

    //  Get current Selected Values
    const livingBase = computed(() => livingValues[props.formData.living] ?? 0);
    const waterValue = computed(() => waterValues[props.formData.water] ?? "0");
    const personsBase = computed(
      () => personsLivingValues[props.formData.personsLiving] ?? 0
    );
    const homeofficeBase = computed(
      () => personsOfficeValues[props.formData.personsOffice] ?? 0
    );

    const isWarmWater = computed(() => waterValue.value == "2");
    // Each Person in the household adds 600kWh to the calculation, if warm water is electric
    const warmWaterAdditional = computed(() =>
      isWarmWater.value ? Number(props.formData.personsLiving) * 600 : 0
    );

    // Max Usage Amount: LivingBase + PersonsBase + warmWaterAdditional + HomeOfficeBase
    const minAmount = computed(
      () =>
        Number(livingBase.value) +
        Number(personsBase.value) +
        Number(warmWaterAdditional.value) +
        Number(homeofficeBase.value)
    );

    const currentUsage = computed(() => Number(props.formData.yearUsage) ?? 0);
    // Calculate how much percent the current consumption deviates from the min amount
    const deviationPercentage = computed(() =>
      currentUsage.value <= minAmount.value
        ? 0
        : Math.abs(
            ((minAmount.value - currentUsage.value) * 100) / minAmount.value
          )
    );
    // Deviation should be capped at 20%
    const safeDeviation = computed(() =>
      Math.min(Math.max(deviationPercentage.value, 0), 20)
    );
    // Calculate how much percent this is of 20 for use on the scale
    const scalePercentage = computed(() =>
      Math.round((safeDeviation.value * 100) / 20)
    );

    const result = computed(() => {
      if (safeDeviation.value <= 5) {
        return {
          titleColor: "text-green-50",
          icon: LowSvg,
          title: "Geringer Verbrauch",
          text:
            "Sehr gut! Ihr Stromverbrauch liegt unter dem Durchschnitt. Sie machen alles richtig.",
        };
      } else if (safeDeviation.value <= 10) {
        return {
          titleColor: "text-yellow",
          icon: AverageSvg,
          title: "Durchschnittlicher Verbrauch",
          text:
            "Ihr Verbrauch liegt im Durchschnitt. Aber es gibt sicherlich Einsparpotenzial. Schauen Sie sich einfach mal unsere Energiespartipps an.",
        };
      } else if (safeDeviation.value <= 15) {
        return {
          titleColor: "text-orange-50",
          icon: IncreasedSvg,
          title: "Erhöhter Verbrauch",
          text:
            "Ihr Stromverbrauch ist überdurchschnittlich hoch. Mit Hilfe unserer Energiespartipps finden Sie sicher Einsparpotential.",
        };
      } else {
        return {
          titleColor: "text-red-500",
          icon: HighSvg,
          title: "Hoher Verbrauch",
          text:
            "Ihr Stromverbrauch ist extrem hoch. Kennen Sie Ihre Stromfresser? Falls nicht, leihen Sie sich gerne ein Strommessgerät von uns.",
        };
      }
    });

    return {
      result,
      scalePercentage,
    };
  },
});
</script>
