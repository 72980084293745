<template>
  <div class="flex text-orange-500">
    <InfoPopup iconText="Berechnungsgrundlage">
      <div class="space-y-6 text-black">
        <h2 class="h2">Berechnungsgrundlage</h2>
        <div class="!mt-8 !mb-6 text-lg font-bold">
          Die Berechnung basiert auf den folgenden Durchschnittswerten:
        </div>

        <div class="font-normal">
          <div class="mt-2">
            Pro Person im Home Office rechnen wir mit einer Standardausstattung
            von
            <b>einem Laptop + einem zusätzlichen Monitor.</b>
          </div>

          <div class="mt-2">
            Das entspricht einem Verbrauch von durchschnittlich
            <b>0,12 kWh pro Tag (8h) Home Office pro Person.</b>
          </div>

          <div class="mt-2">
            Außerdem gehen wir davon aus, dass mindestens
            <b>zwei Tage die Woche im Home Office</b>
            gearbeitet wird.
          </div>
        </div>
      </div>
    </InfoPopup>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import InfoPopup from "@/shared/components/InfoPopup";

export default defineComponent({
  name: "UsageInfoPopup",
  props: {},
  components: { InfoPopup },
});
</script>
