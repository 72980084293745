<template>
  <div class="w-full px-4 pt-4 pb-4 md:px-6 lg:px-8">
    <div class="px-6 py-10 bg-neon-blue-100">
      <h3 class="text-xl leading-tight h2 lg:text-3xl">
        Ist ihr Stromverbrauch zu hoch?
      </h3>
      <form class="calculator-form">
        <div>
          <div class="col-span-1 mt-6 space-y-2 md:mt-8 sm:space-y-0">
            <RadioButtons
              v-model="formData.living"
              :options="livingOptions"
              :currentValue="formData.living"
              name="living"
              title="Wie wohnen Sie?"
            />
          </div>
        </div>
        <div>
          <div class="col-span-1 mt-6 space-y-2 md:mt-8 sm:space-y-0">
            <RadioButtons
              v-model="formData.water"
              :options="waterOptions"
              :currentValue="formData.water"
              name="water"
              title="Ihre Warmwasseraufbereitung:"
            />
          </div>
        </div>
        <div>
          <div class="col-span-1 mt-6 space-y-2 md:mt-8 sm:space-y-0">
            <RadioButtons
              v-model="formData.personsLiving"
              :options="personsLivingOptions"
              :currentValue="formData.personsLiving"
              name="personsLiving"
              title="Wie viele Personen wohnen im Haushalt?"
            />
          </div>
        </div>
        <div>
          <div class="col-span-1 mt-6 space-y-2 md:mt-8 sm:space-y-0">
            <RadioButtons
              v-model="formData.personsOffice"
              :options="personsOfficeOptions"
              :currentValue="formData.personsOffice"
              name="personsOffice"
              title="Wie viele Personen arbeiten im Home Office?"
            />
          </div>
        </div>

        <UsageInfoPopup />

        <div class="flex flex-col mt-6 md:mt-8">
          <BaseInputField
            type="number"
            v-model="formData.yearUsage"
            title="Ihr letzter Jahresverbrauch (kWh):"
            name="Jahresverbrauch"
            rules="required|max:8"
            min="0"
          />
        </div>
      </form>
    </div>

    <UsageResultSlider :formData="formData" :settings="settings" />

    <div class="flex justify-end w-full">
      <BaseButton :href="buttonLink" :title="buttonText" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@vue/composition-api";
import UsageInfoPopup from "@/features/usage-calculator/components/UsageInfoPopup.vue";
import RadioButtons from "@/features/usage-calculator/components/RadioButtons.vue";
import UsageResultSlider from "@/features/usage-calculator/components/UsageResultSlider.vue";

export default defineComponent({
  name: "UsageCalculator",
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
    button: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { UsageInfoPopup, RadioButtons, UsageResultSlider },
  setup(props) {
    const livingOptions = [
      { id: "house", value: "1", title: "Ein- oder Zweifamilienhaus" },
      { id: "flat", value: "2", title: "Wohnung im Mehrfamilienhaus" },
    ];

    const waterOptions = [
      { id: "withoutElectricity", value: "1", title: "Ohne Strom" },
      { id: "withElectricity", value: "2", title: "Mit Strom" },
    ];

    const personsLivingOptions = [
      { id: "oneResident", value: "1", title: "1" },
      { id: "twoResidents", value: "2", title: "2" },
      { id: "threeResidents", value: "3", title: "3" },
      { id: "fourResidents", value: "4", title: "4" },
      { id: "fiveResidents", value: "5", title: "5" },
    ];

    const personsOfficeOptions = [
      { id: "zeroPersonsOffice", value: "0", title: "0" },
      { id: "onePersonsOffice", value: "1", title: "1" },
      { id: "twoPersonsOffice", value: "2", title: "2" },
      { id: "threePersonsOffice", value: "3", title: "3" },
      { id: "fourPersonsOffice", value: "4", title: "4" },
      { id: "fivePersonsOffice", value: "5", title: "5" },
    ];

    const formData = ref({
      living: "2",
      water: "1",
      personsLiving: "3",
      personsOffice: "0",
      yearUsage: "3200",
    });

    const buttonText = computed(() =>
      props.button?.text ? props.button?.text : "Verbrauch optimieren"
    );

    const buttonLink = computed(() =>
      props.button?.link
        ? props.button?.link
        : "https://www.evo-energie.de/energiesparen"
    );

    return {
      formData,
      livingOptions,
      waterOptions,
      personsLivingOptions,
      personsOfficeOptions,
      buttonText,
      buttonLink,
    };
  },
});
</script>

<style>
.calculator-form {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .calculator-form {
    font-size: 16px;
  }

  .calculator-numbers {
    font-size: 19px;
  }
}
</style>
