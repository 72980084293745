/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";

import {
  UsageCalculator,
  UsageCalculatorConfig,
} from "@/features/usage-calculator";

export const initUsageCalculator = () => {
  const el = document.getElementById("usage-calculator");

  if (!el) {
    return;
  }

  try {
    const dataSettings = el.getAttribute("data-config");

    if (!dataSettings) {
      return;
    }

    const parsedSettings: UsageCalculatorConfig = JSON.parse(dataSettings);

    new Vue({
      el,
      render: (h) =>
        h(UsageCalculator, {
          props: {
            settings: parsedSettings?.settings,
            button: parsedSettings?.button,
          },
        }),
    });
  } catch (err) {
    console.error('Failed to load module "UsageCalculator"');
  }
};

initUsageCalculator();
